<template>
  <div>
    <vx-card title="تعديل طلبية" title-color="primary">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label mt-5">مندوب التوصيل</label>
          <v-select
            v-model="order.delivery_man"
            name="delivery_man"
            v-validate="'required'"
            :reduce="(item) => item.id"
            data-vv-as="مندوب التوصيل"
            label="name"
            :options="reps"
          ></v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('delivery_man')"
            >{{ errors.first("delivery_man") }}</span
          >
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label mt-5">تاريخ التوصيل</label>
          <datepicker
            class="w-full"
            format="dd/MM/yyyy"
            v-validate="'required'"
            data-vv-as="تاريخ التوصيل"
            name="delivery_date"
            v-model="order.delivery_date"
            tabindex="2"
          ></datepicker>
          <span
            class="text-danger text-sm"
            v-show="errors.has('delivery_date')"
            >{{ errors.first("delivery_date") }}</span
          >
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label mt-5">سعر التوصيل</label>
          <vs-input
            data-vv-as="سعر التوصيل"
            v-validate="'required|numeric|min:0'"
            type="number"
            class="w-full"
            name="delivery_price"
            v-model="order.delivery_price"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('delivery_price')"
            >{{ errors.first("delivery_price") }}</span
          >
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label mt-5">حالة الطلب</label>
          <v-select
            v-model="order.order_status_id"
            name="order_status"
            v-validate="'required'"
            data-vv-as="حالة الطلب"
            label="status"
            :reduce="(item) => item.id"
            :options="statuses"
          ></v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('order_status')"
            >{{ errors.first("order_status") }}</span
          >
        </div>
      </div>

      <vs-divider></vs-divider>
      <div class="vx-row mt-5 mb-5">
        <div class="vx-col w-full mb-2">
          <vs-table :data="order.items" v-if="order.items">
            <template slot="thead">
              <vs-th>إسم المنتج</vs-th>
              <vs-th>السعر</vs-th>
              <vs-th>الكمية المحددة</vs-th>
              <vs-th>المجموع</vs-th>
              <vs-th>العمليات</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="order.items" :key="index" v-for="(item, index) in data">
                <vs-td>
                  {{
                    item.product.title
                  }}
                </vs-td>
                <vs-td>
                  {{item.product.price}} د.ل
                </vs-td>
                <vs-td>
                  <vs-input
                    :disabled="item.id == null"
                    v-validate="{
                      required: item.product_id != null ? true : false,
                      max_value:
                        item.product_id != null ? item.quantity_details.quantity : 1,
                    }"
                    type="number"
                    name="quantity"
                    v-model="item.quantity"
                    class="w-1/2"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('quantity')"
                    >{{ errors.first("quantity") }}</span
                  >
                </vs-td>


                <!-- <vs-td>
                  <p>
                  {{item.quantity}}
                  </p>
                </vs-td> -->


                <!-- <vs-td v-if="tr.product_id">
                  {{
                    tr.new_price > 0
                      ? tr.new_price * tr.quantity
                      : tr.price * tr.quantity
                  }}
                  د.ل
                </vs-td> -->
                <vs-td v-if="item.id">{{ item.product.price*item.quantity }} د.ل</vs-td>

                <vs-td>
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                    @click.stop="deleteItem(item, index)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-table class="w-1/2 mt-10" data="items" v-if="order.items">
            <template>
              <vs-tr>
                <vs-th>مجموع الطلبية</vs-th>
                <vs-td> {{ order.old_amount }} د.ل</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th>التخفيض</vs-th>
                <vs-td> {{ order.invoice.discount }} د.ل</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th>المبلغ المستحق</vs-th>
                <vs-td> {{ order.total_amount }} د.ل</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th>قيمة الكوبون المستخدم</vs-th>
                <vs-td>
                  <div v-if="order.invoice.coupon">
                    <span v-if="order.invoice.coupon.is_percentage == 1">
                      {{'%' + order.invoice.coupon.value}}
                    </span>
                    <span v-else-if="order.invoice.coupon.is_percentage == 0">
                      {{order.invoice.coupon.value + ' د.ل '}}
                    </span>
                  </div>
                  <div v-else>
                    {{ "لم يتم استخدام كوبون" }}
                  </div>

                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th>التخفيض</vs-th>
                <vs-td>
                  <vs-input
                    type="number"
                    class="w-1/3"
                    name="discount"
                    v-model="order.discount"
                /></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>


      <div class="vx-row mt-8 text-right">
        <div class="vx-col sm:w-full w-full mb-6">
          <vs-button
            class="mr-5 text-lg py-4"
            @click="$router.go(-1)"
            color="warning"
            type="border"
            >إلغاء</vs-button
          >
          <vs-button
            class="mr-5 text-lg px-16 py-4"
            @click="submit"
            color="success"
            type="filled"
            >حفظ</vs-button
          >
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import {mapActions} from "vuex"
export default {
  components: { vSelect, Datepicker },
  data() {
    return {
      statuses:[
        {
          id: 1,
          status: 'قيد المراجعة',
        },
        {
          id: 2,
          status: 'قيد التوصيل',
        },
        {
          id: 3,
          status: 'قيد التجهيز',
        },
        {
          id: 4,
          status: 'تم التوصيل',
        },
        {
          id: 5,
          status: 'تم الإلغاء',
        },
        {
          id: 6,
          status: 'تم الرفض',
        },
        {
          id: 7,
          status: 'معلق',
        },
      ],
      reps:[],
      deleted_order_items:[],
      order: {
        order_status_id: "",
        delivery_date: "",
        delivery_man: "",
        delivery_price: "",
        total_amount: "",
        old_amount: "",
        coupon_value: "",
        discount: 0,
        invoice:'',
        items: [],
      },

    };
  },
  computed: {

  },
  methods: {
    ...mapActions('orders', [
      'fetchOrder',
      'updateClassBOrder',
    ]),
    ...mapActions('reps', [
      'fetchReps',
    ]),
    getData() {
     this.fetchOrder(this.$route.params.id)
        .then((response) => {
          this.order.order_status_id = response.data.data.order_status_id;
          this.order.total_amount = response.data.data.total_amount;
          this.order.old_amount = response.data.data.old_amount;
          this.order.delivery_price = response.data.data.delivery_price;
          this.order.delivery_date = response.data.data.delivery_date;
          this.order.delivery_man = response.data.data.delivery_id;
          this.order.items = response.data.data.items;
          this.order.invoice = response.data.data.invoice;
          // this.order.discount = response.data.data.discount;
          // this.order.coupon_value = response.data.data.coupon_value || 0;
        });
    },
    async submit() {

      // let result = await this.$validator.validateAll().then((result) => {
      //   return result;
      // });

      let formdata = new FormData();
      formdata.set("_method", 'put');
      formdata.set("id", this.$route.params.id);
      formdata.set("order_status_id", this.order.order_status_id);
      formdata.set("discount", this.order.discount);
      formdata.set("delivery_price", this.order.delivery_price);
      formdata.set("delivery_id", this.order.delivery_man);
      formdata.set("delivery_date", this.formatDate(this.order.delivery_date));

      this.order.items.forEach((item,index)=>{
        formdata.set(`products[${item.product_id}][quantity]`, item.quantity);
        formdata.set(`products[${item.product_id}][quantity_id]`, item.quantity_id);
      })

      this.deleted_order_items.forEach((product_id,index)=>{
        formdata.set(`products[${product_id}]`, 0);
      })

      this.updateClassBOrder(formdata)
        .then(() => {
          this.$vs.notify({
            title: "نجاح",
            text: "تمت تعديل الطلب",
            color: "success",
          });
          this.$srouter.push({ name: "stores-management-orders" });
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },

    deleteItem(item, index) {
      if (this.order.items.length > 1){
        this.deleted_order_items.push(item.product_id);
        this.order.items.splice(index, 1);
      }else{
        this.$vs.dialog({
          title: "خطأ في الحذف",
          text: "لا يمكنك حذف جميع الحقول، يجب إدخال حقل واحد على الأقل.",
          color: "danger",
          acceptText: "إغلاق",
        });
      }

    },
  },
  created() {

    this.getData();
    this.fetchReps()
      .then((response) => {
        this.reps = response.data
      });
  },
};
</script>

<style>
.seperator {
  border-left: 1px solid rgb(204, 204, 204);
}
.align-items-center {
  align-items: center;
}
</style>
